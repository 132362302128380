import React from 'react'
import { Flex, Box } from 'rebass'
import { StaticQuery, graphql } from "gatsby"
import { Heading, Image } from 'rebass'

const BackedByIag = props => (
  <StaticQuery query={graphql`
    query BackedByIagQuery
    {
      datoCmsIag {
        logo {
          url
        }
        heading
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `}
  render={data => (
    <Box
      p={[3, 3, 3, 6]}
      bg="#F8FAFD"
      {...props}
    >
      <Box width={[1, 1, 2/3]}>
        <Heading
          color="#373737"
          variant='heading'
          fontWeight={500}
        >
          {data.datoCmsIag.heading}
        </Heading>
      </Box>
      <Flex flexDirection={['column', 'column', 'row']}>
        <Box
          order={[2, 2, 1]}
          width={[1, 1, 1/2]}
          dangerouslySetInnerHTML={{
            __html: data.datoCmsIag.contentNode.childMarkdownRemark.html,
          }}
          pr={[0, 0, 5]}
        />
        <Flex
          order={[1, 1, 2]}
          width={[1, 1, 1/2]}
          alignItems="center"
          justifyContent="center"
          p={[4, 5]}
        >
          <Image
            src={data.datoCmsIag.logo.url}
            alt="Backed by IAG"
            sx={{
              maxWidth: '100%',
              maxHeight: '150px'
            }}
          />
        </Flex>
      </Flex>
    </Box>
    )}
  />
)

export default BackedByIag
