import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Flex, Box } from 'rebass'
import Link from '../components/base/Link'
import Hero from '../components/base/Hero'
import Card from '../components/base/Card'
import BackedByIag from '../components/base/BackedByIag'
import Trustpilot from '../components/base/Trustpilot'
import Section from '../components/base/Section'
import { modelSlugToRoute } from '../utils/links'

const IndexPage = props => (
  <StaticQuery query={graphql`
    query IndexQuery {
      datoCmsHomepage {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        heading
        subheading
        actions {
          ... on DatoCmsProduct {
            id
            name
            slug
            model {
              apiKey
            }
          }
          ... on DatoCmsCategory {
            id
            name
            slug
            model {
              apiKey
            }
          }
        }
        introSections {
          ... on DatoCmsDescription {
            id
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsContent {
            id
            title
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsFeature {
            id
            title
            feature1Node {
              childMarkdownRemark {
                html
              }
            }
            feature2Node {
              childMarkdownRemark {
                html
              }
            }
            feature3Node {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsCoverage {
            id
            title
            includedTitle
            includedNode {
              childMarkdownRemark {
                html
              }
            }
            notIncludedTitle
            notIncludedNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
        }
        cards {
          ... on DatoCmsProduct {
            id
            name
            slug
            description
            model {
              apiKey
            }
            tags {
              id
              label
              icon {
                url
              }
            }
          }
          ... on DatoCmsCategory {
            id
            name
            slug
            description
            model {
              apiKey
            }
            tags {
              id
              label
              icon {
                url
              }
            }
          }
        }
        outroSections {
          ... on DatoCmsDescription {
            id
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsContent {
            id
            title
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsFeature {
            id
            title
            feature1Node {
              childMarkdownRemark {
                html
              }
            }
            feature2Node {
              childMarkdownRemark {
                html
              }
            }
            feature3Node {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsCoverage {
            id
            title
            includedTitle
            includedNode {
              childMarkdownRemark {
                html
              }
            }
            notIncludedTitle
            notIncludedNode {
              childMarkdownRemark {
                html
              }
            }
            model {
              apiKey
            }
          }
        }
      }
    }
  `}
  render={data => (
    <>
      <Hero>
        <Heading as='h1' variant="hero.heading">{data.datoCmsHomepage.heading}</Heading>
        <Heading as='h2' variant="hero.subheading">{data.datoCmsHomepage.subheading}</Heading>
        <Flex mx={-2} flexDirection={['column', 'row']}>
          {data.datoCmsHomepage.actions.map(action =>
            <Link
              key={action.id}
              to={modelSlugToRoute(action.model.apiKey, action.slug)}
              variant="button"
              sx={{ px: 5, py: 2, mx: 2, my: [1, 0], minWidth: 250 }}
            >
              {action.name}
            </Link>
          )}
        </Flex>
      </Hero>
      {props.intro && (
        <Box variant="fixed" my={5}>
          {props.intro}
        </Box>
      )}
      {data.datoCmsHomepage.introSections.map(section => (
        <Box key={section.id} my={5}>
          <Section {...section} />
        </Box>
      ))}
      <Box my={5}>
        <Flex my={5} justifyContent="center" flexDirection={['column', 'column', 'column', 'row']}>
          {data.datoCmsHomepage.cards.map(card =>
            <Card
              key={card.slug}
              name={card.name}
              description={card.description}
              tags={card.tags}
              mx={2}
            >
              <Flex mt="auto" mx={-1}>
                <Link
                  to={modelSlugToRoute(card.model.apiKey, card.slug)}
                  variant='button'
                  sx={{ display: 'block', flex: 1, mx: 1 }}
                >
                  More
                </Link>
              </Flex>
            </Card>
          )}
        </Flex>
        <Trustpilot />
      </Box>
      {data.datoCmsHomepage.outroSections.map(section => (
        <Box key={section.id} my={5}>
          <Section {...section} />
        </Box>
      ))}
      <BackedByIag mt={5} />
    </>
    )}
  />
)

IndexPage.defaultProps = {
  intro: ''
}

export default IndexPage
