import React from 'react'
import { Flex, Image, Box } from 'rebass'

const Tag = props => (
    <Flex
        alignItems="center"
        sx={{
            my: 3
        }}
        {...props}
    >
        <Flex width={50} alignItems="center" justifyContent="center">
            <Image src={props.icon.url} alt='' />
        </Flex>
        <Box ml={3}>{props.label}</Box>
    </Flex>
)

export default Tag
