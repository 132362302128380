import React, { useRef, useEffect } from 'react'

const Trustpilot = props => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef()

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget"
            data-locale="en-AU"
            data-theme="light"
            data-businessunit-id="5d68abb5f7118a000133dbeb"
            {...props}
        >
            <a
                href="https://www.trustpilot.com/review/sharecover.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
  )
}

Trustpilot.defaultProps = {
    'data-template-id': '5406e65db0d04a09e042d5fc',
    'data-style-height': '50px',
    'data-style-width': '100%'
}

export default Trustpilot
