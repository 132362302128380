import React from 'react'
import { Heading, Flex, Box, Text } from 'rebass'
import Tag from './Tag'

const Card = ({ name, tags, description, children, ...props }) => (
    <Flex
        variant='card'
        {...props}
    >
        <Heading
            as="h4"
            variant='heading'
            fontWeight={500}
        >
            {name}
        </Heading>
        <Box>
            {tags.map(tag => <Tag key={tag.label} {...tag} />)}
        </Box>
        <Text sx={{ mb: 3 }}>{description}</Text>
        {children}
    </Flex>
)

export default Card
